<script>
export default {
    props: {
        weight: { type: String, default: '1' },
        text: { type: Boolean, default: false },
    },

    setup(props, { slots }) {
        return () =>
            h(
                'h' + props.weight,
                { class: `headline ${props.text ? 'text' : ''}` },
                h('span', slots.default()),
            )
    },
}
</script>

<style lang="scss" scoped>
.headline {
    display: block;
    text-align: center;

    &.text {
        span {
            background-color: transparent;
            color: theme('colors.primary-dark');
        }
    }

    span {
        background-color: theme('colors.primary-dark');
        color: #fff;
        display: inline;
        padding: 0 0.75rem;
        line-height: 1.2;
        box-decoration-break: clone;
        text-transform: uppercase;
    }
}
</style>
